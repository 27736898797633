<template>
  <div
    class="tw-hidden sm:tw-block tw-bg-gray-50 tw-rounded-lg tw-shadow-sm tw-overflow-hidden lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-4"
  >
    <div
      class="tw-pt-6 tw-pb-8 tw-px-4 sm:tw-pt-10 sm:tw-px-10 lg:tw-py-10 lg:tw-pr-0 xl:tw-py-12 xl:tw-px-12"
    >
      <div class="lg:tw-self-center">
        <h2 class="tw-text-3xl tw-font-normal tw-text-orange sm:tw-text-4xl">
          <span class="tw-block">Need help or support?</span>
          <span class="tw-block">Let us know how we can help you!</span>
        </h2>
        <p class="tw-mt-4 tw-text-lg tw-leading-6 tw-text-black-500">
          We've developed a range of help and how-to articles to get you
          started. If you are experiencing any issues or have any feedback,
          please contact our support team.
        </p>
        <a
          href="https://support.prismgroup.io/en/collections/2536513-risky-live-by-riskfacilitator"
          target="_blank"
          class="tw-mr-2 tw-mt-4 tw-bg-orange tw-border tw-border-transparent tw-rounded-md tw-shadow tw-px-6 tw-py-3 tw-inline-flex tw-items-center tw-text-base tw-font-medium tw-text-white hover:tw-bg-orange-600"
          >How-to articles<icon
            :icon="['fal', 'external-link-alt']"
            class="ml-2"
          />
        </a>
        <button
          type="button"
          @click="$modal.show()"
          class="tw-mt-4 tw-bg-gray-100 tw-border tw-border-transparent tw-rounded-md tw-shadow tw-px-6 tw-py-3 tw-inline-flex tw-items-center tw-text-base tw-font-medium tw-text-gray-600 hover:tw-bg-gray-200"
          >Contact support</button
        >
      </div>
    </div>
    <div class="tw--mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
      <support-icon
        class="tw-h-72 tw-transform tw-translate-x-6 tw-translate-y-6 tw-rounded-md tw-object-cover tw-object-left-top sm:tw-translate-x-16 lg:tw-translate-y-20"
      ></support-icon>
    </div>
  </div>
</template>

<script>
import SupportIcon from "@/components/ui/Illustrations/SupportIcon.vue";
export default {
  components: { SupportIcon },
};
</script>

<style>
</style>