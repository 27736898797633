<template>
  <div>
    <support-banner class="tw-mb-6"></support-banner>
    <help-card-list></help-card-list>
  </div>
</template>

<script>
import SupportBanner from "@/components/Help/SupportBanner.vue";
import HelpCardList from "@/components/Help/HelpCardList.vue";

export default {
  components: {
    SupportBanner,
    HelpCardList,
  },
};
</script>